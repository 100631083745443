import { useEffect, useState, useContext } from 'react';
import { PricingTable } from 'components/PricingTable';
import { store } from 'store';
import { axios } from 'config';
import { scrollTo } from 'util';
import { 
   Row, Col, Typography, Form, Input, Button, Alert, Modal, Spin, Checkbox, Avatar, InputNumber, Tooltip  
} from 'antd';
import {
   LoadingOutlined,
   QuestionCircleOutlined
} from '@ant-design/icons';


const { Title, Text } = Typography;

export default function TikTokRandomCommentPicker(props) {
   const { state } = useContext(store)

   const [isPremium, setIsPremium] = useState(false);
   const [form, setForm] = useState({ 
      url: '', 
      searchWord: '', 
      numberOfMentions: 0, 
      includeDoublicates: false, 
      includeCreator: false 
   });
   const [formStatus, setFormStatus] = useState({ disabled: false, error: '' });
   const [videoId, setVideoId] = useState(null);

   const [totalComments, setTotalComments] = useState(0);
   const [pricingVisible, setPricingVisible] = useState(false);
   let filterModal = null;
   let spinnerModal = null;

   async function handleFormSubmit(data) {
      setForm((state) => {
         state.url = data.url;
         state.searchWord = data.search_word;

         return {...state}
      })
      setFormStatus({ disabled: true, error: '' })

      try  {
         data.offset = 0;
         data.count = 1;

         let response = await axios.post('/tool/tiktok-get-comments', data)

         setTotalComments(response.data.total);
         setVideoId(response.data.videoId)
         setFormStatus({ disabled: false, error: '' })

         scrollTo('#loadComments')
      } catch(error) {
         console.log(error);
         setFormStatus({ disabled: false, error: error.response.data.message })
      }
   }

   let comments = []

   async function loadAllComments() {
      if(comments.length === 0) {
         filterModal = Modal.info({
            wrapClassName: 'ant-modal-centered',
            closable: false,
            title: 'Hold on.',
            content: (
               <Title level={5} className="pt-3">
                  <Spin indicator={<LoadingOutlined className='me-1' spin />} />
                  Loading comments: 0
               </Title>
            ),
            okText: 'Pick a winner',
            okButtonProps: {
               className: 'd-none'
            },
            onOk: function() {
               pickWinner();

               return false;
            }
         })
      }

      try  {
         let params = {
            url: form.url,
            offset: comments.length,
            count: 50
         }

         if(isPremium == false && (comments.length + params.count) > 300) {
            params.count = 300 - comments.length;
         }
         
         let response = await axios.post('/tool/tiktok-get-comments', params)

         comments = comments.concat(response.data.comments)

         filterModal.update({
            content: (
               <Title level={5} className="pt-3">
                  <Spin indicator={<LoadingOutlined className='me-1' spin />} />
                  Loading comments: {comments.length}
               </Title>
            ),
         })

         function startFiltering() {
            filterModal.update({
               content: (
                  <>
                     <Title level={5} className="pt-3" type='success'>
                        Loading done! {comments.length} Comments.
                     </Title>
                     <Title level={5}>
                        <Spin indicator={<LoadingOutlined className='me-1' spin />} />
                        Filtering comments: 0
                     </Title>
                  </>
               ),
            })

            filterComments()
         }

         if(isPremium) {
            if(response.data.hasMore) {
               loadAllComments()
            } else {
               startFiltering()
            }
         } else {
            if(response.data.hasMore && comments.length < 300) {
               loadAllComments()
            } else {
               startFiltering()
            }
         }
      } catch(error) {
         console.log(error)

         Modal.info({
            title: 'Error',
            content: error.response.data.message,
         });
      }
   }

   let filteredComments = [];

   function filterComments() {
      let searchWord = form.searchWord;
      let numberOfMentions = form.numberOfMentions;
      let includeDoublicates = form.includeDoublicates;
      let includeCreator = form.includeCreator;
      
      comments.forEach((comment, index) => {
         if(includeCreator === false) {
            if(comment.label_text === 'Creator') return;
         }

         if(searchWord) {
            let term = searchWord.toLowerCase();
            if(comment.text.toLowerCase().includes(term) === false) return;
         }

         if(numberOfMentions) {
            let mentions = comment.text.match(/@\w+/gm); 
            
            if(mentions === null) {
               return;
            } else {
               if(mentions.length < numberOfMentions) return;
            }
         }

         filteredComments.push(comment)
      })

      if(includeDoublicates === false) {
         let userIds = filteredComments.map( comment => comment.user.uid);
         let uniqueComments = filteredComments.filter((comment, index) => {
            return !userIds.includes(comment.user.uid, index + 1)
         })

         filteredComments = uniqueComments;
      }

      filterModal.update({
         content: (
            <>
               <Title level={5} className="pt-3" type='success'>
                  Loading done! {comments.length} Comments.
               </Title>
               <Title level={5} type='success'>
                  Filtering done! {filteredComments.length} Comments.
               </Title>
            </>
         ),
         okButtonProps: {
            className: ''
         }
      })
   }

   function pickWinner () {
      spinnerModal = Modal.info({
         wrapClassName: 'ant-modal-centered',
         closable: false,
         title: 'Packing random winner.',
         content: (
            <div className='ps-3 py-4 text-center'>
               <Spin size='large'/>
            </div>
         ),
         okButtonProps: {
            className: 'd-none'
         }
      })

      setTimeout(() => {
         let randomNumber = generateRandomNumber(0, filteredComments.length -1);
         let randomComment = filteredComments[randomNumber];

         Modal.info({
            wrapClassName: 'ant-modal-centered',
            closable: true,
            title: 'Random winner is ...',
            content: (
               <div className='text-center'>
                  <Avatar src={randomComment.user.avatar_300x300.url_list[2]} size={100} />
                  <Title level={5}>@{randomComment.user.unique_id}</Title>
                  <Text>{randomComment.text}</Text>
                  <div className='pt-3'>
                     <Button 
                        ghost
                        type='primary' 
                        href={'https://www.tiktok.com/@' + randomComment.user.unique_id} 
                        target='_blank'
                     >
                        Go to Profile
                     </Button>
                  </div>
               </div>
            ),
            okText: 'Pick the next random winner',
            onOk: function() {
               pickWinner()

               return false;
            }
         })

         spinnerModal.destroy();
      }, 5000);
   }

   function generateRandomNumber(min, max) { 
      return Math.floor(Math.random() * (max - min + 1) + min)
   }

   useEffect(() => {
      if(state.user && state.user.tiktok_random_comment_picker_available) {
         setIsPremium(true)
      }
   }, [state])
   return (
      <>
         <Title 
            level={3} 
            className='text-center mt-5'
            type={isPremium ? 'warning' : null}
         >
            Random TikTok Comment Picker {isPremium ? ' Premium' : null}
         </Title>
         <Text className='d-block text-center mb-4'>
            The only professional and fair Tiktok giveaway tool.
         </Text>

         <Form
            name='form'
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={handleFormSubmit}
            autoComplete='off'
         >
            <Row justify='center'>
               <Col xs={12} sm={8} md={4}>
                  <Form.Item
                     name='url'
                     className='flex-column'
                     label='Enter TikTok Video URL'
                     labelAlign='left'
                     rules={[{ required: true, message: 'Please enter your TikTok Video URL!' }]}
                  >
                     <Input size='large' placeholder='Enter TikTok Video URL' />
                  </Form.Item>
               </Col>
            </Row>
            <Row justify='center'>
               <Col xs={12} sm={8} md={4}>
                  <Form.Item
                     name='search_word'
                     className='mb-1 flex-column'
                     label='Search a word'
                     labelAlign='left'
                     tooltip={{ title: 'If provided only comments that includes the given word are eligible to participate in the giveaway.', color: 'blue' }}
                  >
                     <Input size='small' placeholder='Inlcude this word' />
                  </Form.Item>
               </Col>
            </Row>
            <Row justify='center'>
               <Col xs={12} sm={8} md={4}>
                  <Form.Item
                     className='mb-1 flex-column'
                     label='Number of mentions'
                     labelAlign='left'
                     tooltip={{ title: 'Minimum number of mentions in a single comment to be are eligible to participate in the giveaway.', color: 'blue' }}
                  >
                     <InputNumber 
                        controls={true} 
                        style={{ width: '100%'}} 
                        size='small' 
                        min={0} 
                        placeholder='Enter a number'
                        formatter={value => value.replace(/\D/g,'')}
                        parser={value => value.replace(/\D/g,'')}
                        onChange={(value) => { setForm((state) => { 
                           state.numberOfMentions = value; 

                           return {...state}; 
                        }) }}
                     />
                  </Form.Item>
               </Col>
            </Row>
            <Row justify='center'>
               <Col xs={12} sm={8} md={4}>
                  <Form.Item className='mb-0'>
                     <Checkbox
                        onChange={(e) => { setForm((state) => {
                           state.includeDoublicates = e.target.checked;
                           
                           return {...state};
                        }) }}
                     >
                        Allow duplicate entries
                        <Tooltip title='This allows multiple comments by a user to be loaded in the giveaway.' color='blue' className='ms-1'>
                           <QuestionCircleOutlined />
                        </Tooltip>
                     </Checkbox>
                  </Form.Item>
               </Col>
            </Row>
            <Row justify='center'>
               <Col xs={12} sm={8} md={4}>
                  <Form.Item className='mb-4 justify-content-center'>
                     <Checkbox
                        onChange={(e) => { setForm((state) => {
                           state.includeCreator = e.target.checked;

                           return {...state};
                        }) }}
                     >
                        Include your own comments
                        <Tooltip title='This allows the owner of the post to also participate in the giveaway' color='blue' className='ms-1'>
                           <QuestionCircleOutlined />
                        </Tooltip>
                     </Checkbox>
                  </Form.Item>
               </Col>
            </Row>
            
            <Row justify='center'>
               <Col xs={12} sm={8} md={4} className='mb-3'>
                  <Alert 
                     type='warning'
                     showIcon={true}
                     message={'Attention! This tool does not include comment replies.'}
                  />
               </Col>
            </Row>

            <Row justify='center'>
               <Col xs={12} sm={8} md={4} className='mb-3'>
                  {Boolean(formStatus.error) &&
                     <Alert 
                        type='error'
                        showIcon={true}
                        message={formStatus.error}
                     />
                  }
               </Col>
            </Row>
            <Row justify='center'>
               <Col xs={8} sm={4} md={2}>
                  <Form.Item>
                     <Button type='primary' htmlType='submit' block loading={formStatus.disabled}>
                        Load Comments
                     </Button>
                  </Form.Item>
               </Col>
            </Row>

         </Form>
         
         <iframe 
            className={videoId ? null : 'd-none'}
            src={videoId ? 'https://www.tiktok.com/embed/v2/'+ videoId +'?lang=en-US&amp;referrer=http%3A%2F%2Flocalhost%3A3000%2Ftiktok-random-comment-picker' : null} 
            style={{
               marginBottom: '30px',
               width: '100%', 
               height: '728px', 
               display: 'block', 
               border: 'none', 
               visibility: 'unset'
            }}
         />

         <div className={videoId ? 'text-center mb-5' : 'd-none'} id='loadComments'>
            <Alert 
               message={'Post has '+ totalComments +' comments in total (including replies).'} 
               type='info' 
               showIcon 
               className='container-500 my-3'
            />
            <Row justify='center' gutter={[10, 10]} className='mb-5'>
               <Col>
                  <Button 
                     type='primary'
                     onClick={() => {
                        if(isPremium) {
                           loadAllComments()   
                        } else {
                           scrollTo('#pricingTable', 'start');
                           setPricingVisible(true);
                        }
                     }}
                  >
                     Load All Comments (Premium)
                  </Button>
               </Col>
               {isPremium == false ? (
                  <Col>
                     <Button 
                        danger
                        ghost 
                        onClick={() => {
                           loadAllComments()
                        }}
                     >
                        Load 300 Comments (Free)
                     </Button>
                  </Col>
                  ) : ( null )
               }
            </Row>
         </div>
         <PricingTable
            visible={pricingVisible}
            pricingPackages={[
               {
                  title: 'Free',
                  features: [
                     'Up to 300 comments',
                     'Unlimited Winners', 
                  ],
                  price: null
               },
               {
                  title: 'Standart',
                  features: [
                     'Up to 1,000 comments', 
                     'Unlimited Winners', 
                  ],
                  price: 5,
                  priceType: 'per post',
                  checkout: {
                     servicetitle: 'Random TikTok Comment Picker',
                     serviceCode: 'tiktok_random_comment_picker',
                     serviceTier: 'Standart',
                     servicePrice: 5
                  }
               },
               {
                  title: 'Plus',
                  features: [
                     'Up to 5,000 comments', 
                     'Unlimited Winners', 
                  ],
                  price: 10,
                  priceType: 'per post',
                  checkout: {
                     servicetitle: 'Random TikTok Comment Picker',
                     serviceCode: 'tiktok_random_comment_picker',
                     serviceTier: 'Plus',
                     servicePrice: 10
                  }
               },
               {
                  title: 'Premium',
                  features: [
                     '+5,000 comments', 
                     'Unlimited Winners', 
                  ],
                  price: 25,
                  priceType: 'per post',
                  checkout: {
                     servicetitle: 'Random TikTok Comment Picker',
                     serviceCode: 'tiktok_random_comment_picker',
                     serviceTier: 'Plus',
                     servicePrice: 25
                  }
               }
            ]}
         />
         <script async src='https://www.tiktok.com/embed.js'></script>
      </>
   );
}