import { useState, useContext, useEffect } from 'react';
import { Row, Col, Typography, Form, Input, Button, Alert  } from 'antd';
import { store } from 'store';
import { axios } from 'config';
import { useNavigate } from 'react-router-dom';


const { Title, Text } = Typography;

export default function Login(props) {
   const { state, dispatch } = useContext(store)
   const navigate = useNavigate();

   const [formStatus, setFormStatus] = useState({ disabled: false, error: '' });

   async function handleFormSubmit(data) {
      setFormStatus({ disabled: true, error: '' })

      try  {
         let response = await axios.post('/user/login', data)
         let userData = response.data.user;

         setFormStatus({ disabled: false, error: '' })

         dispatch({ type: 'LOGIN', payload: { email: data.email, user: userData  } })
      } catch(error) {
         console.log(error);
         setFormStatus({ disabled: false, error: error.response.data.message })
      }
   }

   useEffect(() => {
      if(state.isReady) {
         if(state.user) {
            navigate('/dashboard')
         }
      }
   })

   return (
      <>
         <Title level={3} className='text-center mt-5'>
            Login
         </Title>
         <Text className='d-block text-center mb-4'>
            Login to your account to user premium services you bought.
         </Text>

         <Form
            name='form'
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={handleFormSubmit}
            autoComplete='off'
         >
            <Row justify='center'>
               <Col xs={12} sm={8} md={4}>
                  <Form.Item
                     name='email'
                     className='flex-column'
                     label='Login'
                     labelAlign='left'
                     rules={[{ required: true, message: 'Please enter your email!' }]}
                  >
                     <Input size='large' placeholder='Enter your email' />
                  </Form.Item>
               </Col>
            </Row>

            <Row justify='center'>
               <Col xs={12} sm={8} md={4}>
                  <Form.Item
                     name='password'
                     className='flex-column'
                     label='Password'
                     labelAlign='left'
                     rules={[{ required: true, message: 'Please enter your password!' }]}
                  >
                     <Input size='large' type='password' placeholder='Enter your password' />
                  </Form.Item>
               </Col>
            </Row>
            
            <Row justify='center'>
               <Col xs={12} sm={8} md={4} className='mb-3'>
                  {Boolean(formStatus.error) &&
                     <Alert 
                        type='error'
                        showIcon={true}
                        message={formStatus.error}
                     />
                  }
               </Col>
            </Row>
            <Row justify='center'>
               <Col xs={8} sm={4} md={2}>
                  <Form.Item>
                     <Button type='primary' htmlType='submit' block loading={formStatus.disabled}>
                        Login
                     </Button>
                  </Form.Item>
               </Col>
            </Row>

         </Form>
      </>
   );
}