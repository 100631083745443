import { useState, useContext } from 'react';
import { Layout, Menu, Row, Col, Typography, Drawer, Button, Avatar  } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { Link, Outlet, useNavigate } from "react-router-dom"; 
import { store } from '../store';
const { Header, Content, Footer  } = Layout;
const { Title } = Typography;



function Main(props) {
   const { state, dispatch } = useContext(store)
   const navigate = useNavigate();

   const [drawer, setDrawer] = useState(false);

   return (
      <Layout>
         <Header>
            <Row align="middle" justify="space-between">
               <Col>
                  <Link to="/">
                     <Title level={4} className="mb-0">
                        TikTuls
                     </Title>
                  </Link>
               </Col>
               <Col>
                  {Boolean(state.user) &&
                     <Avatar className='me-2'>{state.user.name[0]}</Avatar>
                  }
                  <Button type="primary" onClick={() => { setDrawer(!drawer) }}>
                     <MenuOutlined /> Menu
                  </Button>
                  <Drawer
                     title="Menu"
                     width={270}
                     visible={drawer}
                     onClose={() => { setDrawer(false) }}
                  >
                     <Menu>
                        {Boolean(state.user) && 
                        <Menu.Item 
                           key={4}
                           onClick={() => {
                              setDrawer(false)
                              navigate('/dashboard')
                           }}
                        >
                           Dashboard
                        </Menu.Item>
                        }
                        <Menu.Item 
                           key={1}
                           onClick={() => {
                              setDrawer(false)
                              navigate('/')
                           }}
                        >
                           Home
                        </Menu.Item>
                        <Menu.Item 
                           key={2}
                           onClick={() => {
                              setDrawer(false)
                              navigate('/about')
                           }}
                        >
                           About
                        </Menu.Item>
                        <Menu.Item 
                           key={3}
                           onClick={() => {
                              setDrawer(false)
                              navigate('/contact')
                           }}
                        >
                           Contact
                        </Menu.Item>
                     </Menu>
                     {Boolean(state.user) ? (
                        <Button 
                           type="primary" 
                           block
                           onClick={() => {
                              setDrawer(false)
                              dispatch({ type: 'LOGOUT' })
                           }}
                        >
                           Log out
                        </Button>
                      ) : (
                        <Button 
                           type="primary" 
                           block
                           onClick={() => {
                              setDrawer(false)
                              navigate('/login')
                           }}
                        >
                           Log in
                        </Button>
                      )
                     }
                  </Drawer>
               </Col>
            </Row>
         </Header>
         <Content style={{ margin: '24px 16px 0' }}>
            <Outlet /> 
         </Content>
         <Footer className='py-4'></Footer>
      </Layout>
   );
}

export default Main;
