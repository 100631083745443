import { createContext, useReducer, useEffect } from 'react';
import { io } from 'socket.io-client';
import { baseURL } from './../config';
import { axios } from '../config/index.js';

const socket = io.connect(baseURL, { 
   credentials: true
});

socket.on("connect", () => {
   console.log('Connected:');
   console.log(socket.id);
});

const initialState = {
   user: null,
   isReady: false,
   tiktokId: null,
   socket: null
};

const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
   const [state, dispatch] = useReducer((oldState, action) => {
      let state = {...oldState};
      
      switch (action.type) {
         case 'LOGIN':
            if( !action.payload.email || !action.payload.user ) {
               throw new Error('Invalid dispatch payload provided');
            }

            localStorage.setItem('user_email', action.payload.email)
            state.user = action.payload.user;
            
            return state;
         case 'LOGOUT':
            localStorage.removeItem('user_email')
            state.user = null;
            
            return state;
         case 'SET_STORE_READY':
            state.isReady = true;
            
            return state;
         case 'SET_TIKTOK_ID':
            state.tiktokId = action.payload.id;
            
            return state;
         case 'SET_SOCKET':
            if( !action.payload.socket ) throw new Error('Invalid dispatch payload provided');
            
            state.socket = action.payload.socket;
            
            return state;
         default:
            throw new Error('Invalid dispatch type!');
      };

   }, initialState);

   async function loadInitialState() {
      let userEmail = localStorage.getItem('user_email');

      if(userEmail) {
         try {
            let params = { email: userEmail };

            let response = await axios.post('/user/get-user', params)
            let userData = response.data.user;

            dispatch({ type: 'LOGIN', payload: { email: userEmail, user: userData  } })
         } catch (error) {
            console.log('error')
            console.log(error)
         }
      }

      let tiktokId = localStorage.getItem('tiktok_id');

      if(tiktokId) {
         dispatch({ type: 'SET_TIKTOK_ID', payload: { id:  tiktokId } })
      }

      dispatch({ type: 'SET_SOCKET', payload: { socket: socket } })

      dispatch({ type: 'SET_STORE_READY' })
   }

   useEffect(() => {
      loadInitialState()
   }, [])
   
   return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider }