import { default as axiosBase } from 'axios';

let apiURL = 'http://localhost:8080';

if(process.env.NODE_ENV != 'development') {
   apiURL = 'http://api.tiktuls.com';
}

export const baseURL = apiURL;

export const axios = axiosBase.create({
   baseURL: baseURL + '/api'
})
