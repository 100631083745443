import { useContext } from 'react';
import { store } from 'store';

import Dashboard from './Dashboard';
import Login from './Login';

export default function GetTikTokFollowers(props) {
   const { state } = useContext(store);

   if(state.tiktokId) {
      return <Dashboard />
   } else {
      return <Login /> 
   }
}