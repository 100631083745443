import { useEffect, useContext } from 'react';
import { useNavigate, useLocation  } from 'react-router-dom'; 
import { axios } from 'config';
import { store } from 'store';
import { LogoutOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom"; 
import { Typography, Button, Descriptions, Alert } from 'antd';


const { Title } = Typography;

export default function UserDashboard(props) {
   const { state, dispatch } = useContext(store)
   const navigate = useNavigate();
   const location = useLocation();

   function formatDate(availability, dateString) {
      if(availability == false) {
         return 'Not available'
      }

      let date = new Date(dateString)
      let formated = date.toLocaleDateString('en-US',{ dateStyle: 'medium' })

      return 'Until ' + formated;
   }

   async function updateUserData() {
      let userEmail = localStorage.getItem('user_email');

      if(userEmail) {
         try {
            let params = { email: userEmail };

            let response = await axios.post('/user/get-user', params)
            let userData = response.data.user;

            dispatch({ type: 'LOGIN', payload: { email: userEmail, user: userData  } })
         } catch (error) {
            console.log('error')
            console.log(error)
         }
      }
   }
   useEffect(() => {
      updateUserData()
   }, [])

   useEffect(() => {
      if(state.isReady && state.user == null) {
         navigate('/')
      }
   }, [state])

   const Creds = function(props) {
      return (
         <div>
            <b>Your login:</b> {location.state.login} <br />
            <b>Your password:</b> {location.state.password} <br /> <br />
            
            Your login credentials are also sent to your PayPal email.
         </div>
      )
   }

   return (
      <>
         {Boolean(location.state) &&
            <Alert 
               showIcon
               type='warning' 
               className='container-500'
               message='Your account credentials:'
               description={<Creds />}
            />
         }
         <Title level={3} className='text-center mb-5 mt-5'>
            Your Subscriptions And Products
         </Title>
         
         <div className='container-700 mb-5'>
            <Descriptions bordered layout='vertical'>
               <Descriptions.Item label='Service title'>
                  <Link to="/youtube-trimmer">Youtube Trimmer</Link>
               </Descriptions.Item>
               <Descriptions.Item label='Type'>Subscription</Descriptions.Item>
               <Descriptions.Item label='Available'>
                  { state.user ? 
                     formatDate(state.user.youtube_trimmer_available, state.user.youtube_trimmer) : null 
                  }
               </Descriptions.Item>

               <Descriptions.Item label='Service title'>
                  <Link to="/tiktok-vidoes-merger">TikTok Merger</Link>
               </Descriptions.Item>
               <Descriptions.Item label='Type'>Subscription</Descriptions.Item>
               <Descriptions.Item label='Available'>
                  { state.user ? 
                     formatDate(state.user.tiktok_videos_merger_available, state.user.tiktok_videos_merger) : null 
                  }
               </Descriptions.Item>
               
               <Descriptions.Item label='Service title'>
                  <Link to="/tiktok-random-comment-picker">TikTok Random Comment Picker</Link>
               </Descriptions.Item>
               <Descriptions.Item label='Type'>Per post</Descriptions.Item>
               <Descriptions.Item label='Available'>
                  { state.user ? state.user.tiktok_random_comment_picker : null } post(s)
               </Descriptions.Item>
            </Descriptions>
         </div>

         <Button 
            ghost 
            className='d-block px-5 mb-3 container-500' 
            type='danger' 
            block
            onClick={() => dispatch({ type: 'LOGOUT' })}
         >
            <LogoutOutlined /> Log out
         </Button>
      </>
   );
}