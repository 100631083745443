import { useState, useContext, useEffect } from 'react';
import { store } from 'store';
import { scrollTo } from 'util';
import { axios, baseURL } from 'config/index.js';
import ProgressBar from 'components/ProgressBar';
import { PricingTable } from 'components/PricingTable';
import { 
   Row, Col, Typography, Form, Input, Button, Alert, Empty, notification
} from 'antd';
import { 
   DoubleLeftOutlined, LeftOutlined, DoubleRightOutlined, RightOutlined, PlusOutlined, DeleteFilled 
} from '@ant-design/icons';

const { Title, Text } = Typography;

export default function TikTokVideosMerger(props) {
   const { state } = useContext(store)
   const [isPremium, setIsPremium] = useState(false);
   const [form] = Form.useForm();
   const [videos, setVideos] = useState([]); 
   const [formStatus, setFormStatus] = useState({ error: '', disabled: false });
   const mergeInitialStatus = { error: '', disabled: false, process: 0, info: 'Merging videos...' };
   const [mergeStatus, setMergeStatus] = useState(mergeInitialStatus);
   const [fileName, setFileName] = useState('');
   const [pricingVisible, setPricingVisible] = useState(false);

   async function handleFormSubmit(data) {
      setFormStatus({ error: '', disabled: true });

      if(isPremium == false) {
         if(videos.length >= 5) {
            notification.info({
               duration: null,
               placement: 'top',
               message: 'Attention!',
               description: 'You can merge up to 5 videos for free. If you want to merge more than 5 videos and want more features you have to buy premium version of the service.',
               btn: (<Button 
                  type='primary'
                  onClick={() => {
                     scrollTo('#pricingTable', 'start');
                     setPricingVisible(true);
                     notification.destroy();
                  }}
               >
                  Buy Premium Version
               </Button>)
            });
   
            setFormStatus({ error: '', disabled: false });
   
            return;
         }
      }

      try  {
         let response = await axios.get('/util/get-tiktok-video-url', { params: data })

         setVideos((state) => {
            state.push({ 
               order: state.length + 1,
               id: response.data.id,
               playURL: response.data.playURL,
               url: data.url,
            })

            return [...state];
         })

         form.resetFields();
         setFormStatus({ error: '', disabled: false });
      } catch(error) {
         console.log(error);
         setFormStatus({ error: error.response.data.message, disabled: false });
      }
   }

   function moveToStart(index) {
      setVideos((state) => {
         let selectedVideo = state[index];
         
         state.forEach((item, i, arr) => {
            if(selectedVideo.order > item.order) {
               arr[i].order = item.order + 1;
            }
         })

         state[index].order = 1;
         
         return [...state]
      })
   }

   function moveToEnd(index) {
      setVideos((state) => {
         let selectedVideo = state[index];

         state.forEach((item, i, arr) => { 
            if(selectedVideo.order < item.order){
               arr[i].order = item.order - 1;
            }
         })
         
         state[index].order = state.length;

         return [...state]
      })
   }

   function moveToLeft(index) {
      setVideos((state) => {
         let selectedVideo = state[index];

         if(selectedVideo.order - 1 === 0) return state;

         state.forEach((item, i, arr) => { 
            if(selectedVideo.order === item.order + 1){
               arr[i].order = selectedVideo.order;
            }
         })
         
         state[index].order = selectedVideo.order - 1;

         return [...state]
      })
   }
   function moveToRight(index) {
      setVideos((state) => {
         let selectedVideo = state[index];

         if(selectedVideo.order + 1 > state.length) return state;

         state.forEach((item, i, arr) => { 
            if(selectedVideo.order === item.order - 1){
               arr[i].order = selectedVideo.order;
            }
         })
         
         state[index].order = selectedVideo.order + 1;

         return [...state]
      })
   }

   function removeVideo(index) {
      setVideos((state) => {
         let selectedVideo = state[index];

         state.forEach((item, i, arr) => { 
            if(selectedVideo.order < item.order){
               arr[i].order = item.order - 1;
            }
         })
         
         state.splice(index, 1)

         return [...state]
      })
   }

   async function handleMerge() {
      if(videos.length < 2) return;

      setMergeStatus((state) => {
         state = mergeInitialStatus;
         state.progress = 1;
         state.disabled = true;

         return {...state}
      })

      try  {
         let response = await axios.post('/tool/tiktok-merge-videos', { 
            videos: videos.sort((a, b) => a.order - b.order),
            socketId: state.socket.id
         })
      } catch(error) {
         console.log(error);
         setMergeStatus((state) => {
            state.error = error.response.data.message;
   
            return {...state}
         })
      }
   }

   useEffect(() => {
      if(state.user && state.user.tiktok_videos_merger_available) {
         setIsPremium(true)
      }
      
      if(state.socket) {
         state.socket.on('merging_videos', (value) => {
            setMergeStatus((state) => {
               state.progress = value;
               
               return {...state};
            })
            
         })

         state.socket.on('merging_videos_success', (fileName) => {
            setMergeStatus((state) => {
               state.disabled = false;
   
               return {...state};
            })
            setFileName(fileName);
         })

         state.socket.on('merging_videos_error', (message) => {
            setMergeStatus((state) => {
               state.error = message;
               state.disabled = false;
   
               return {...state};
            })
         })
      }
   }, [state])

   return (
      <>
         <Title 
            level={3} 
            className='text-center mt-5'
            type={isPremium ? 'warning' : null}
         >
            TikTok Videos Merger {isPremium ? ' Premium' : null}
         </Title>
         <Text className='d-block text-center mb-4'>
            Merge TikTok videos into a single video easliy without any software.
         </Text>
         <Form 
            name='basic' 
            form={form}
            onFinish={handleFormSubmit} 
            autoComplete='off'
         >
            <Form.Item
               name='url'
               className='w-100 justify-content-center mb-4'
               rules={[
                  { required: true, message: 'Please enter a Youtube URL!' } 
               ]}
               wrapperCol={{ xs: 12, sm: 8, lg: 5 }}
            >
               <Input size='large' placeholder='Enter a TikTok video URL' />
            </Form.Item>
            {Boolean(formStatus.error) && 
               <Alert 
                  className='mb-3 container-500'
                  type='error'
                  showIcon={true}
                  message={formStatus.error}
               />
            }
            <Form.Item
               className='w-100 justify-content-center'
               wrapperCol={{ xs: 8, sm: 4, lg: 3 }}
            >
               <Button type='primary' htmlType='submit' block loading={formStatus.disabled}>
                  <PlusOutlined /> Add to merging list
               </Button>
            </Form.Item>
         </Form>
         <Row gutter={[10, 15]}>
            {videos.map((video, index) => (  
               <Col xs={6} sm={2} key={index} style={{ order: video.order }}>
                  <div 
                     className='ant-card ant-card-bordered h-100 d-flex flex-column justify-content-end'
                  >
                     <video 
                        width={'100%'}
                        controls
                        src={video.playURL}
                     />
                     <Row justify='space-between' className='p-1 pb-2'>
                        <Col span={12}>
                           <Button 
                              className='mb-4'
                              danger 
                              type='primary'
                              block
                              size='small'
                              onClick={() => removeVideo(index)}
                           >
                              <DeleteFilled />
                           </Button>
                        </Col>
                        <Col>
                           <Button onClick={() => moveToStart(index)} type='primary' size='small'>
                              <DoubleLeftOutlined />
                           </Button>
                           <Button onClick={() => moveToLeft(index)} type='primary' size='small' className='ms-1'>
                              <LeftOutlined />
                           </Button>
                        </Col>
                        <Col>
                           <Button onClick={() => moveToRight(index)} type='primary' size='small' className='me-1'>
                              <RightOutlined />
                           </Button>
                           <Button onClick={() => moveToEnd(index)} type='primary' size='small'>
                              <DoubleRightOutlined />
                           </Button>
                        </Col>
                     </Row>
                  </div>
               </Col>
            ))}

            {Boolean(videos.length === 0) && 
               <Empty className='mx-auto' image={Empty.PRESENTED_IMAGE_SIMPLE} description="No videos to merge" />
            }
         </Row>
         <div className='mb-5'>
            
            <div className='text-center pt-5'>
               <Title level={5}>You can change videos order:</Title>
               <Row 
                  justify='space-between' 
                  className='mx-auto text-left mb-4' 
                  style={{ maxWidth: '500px' }}
                  gutter={[0, 10]}
               >
                  <Col>
                     <Button ghost type='primary' size='small'><DoubleLeftOutlined /></Button>
                     <Text> - Moves to the beginning</Text>
                     <div className='mb-2 d-block' />
                     <Button ghost type='primary' size='small'><DoubleRightOutlined /></Button>
                     <Text> - Moves to the end</Text>
                  </Col>
                  <Col>
                     <Button ghost type='primary' size='small'><LeftOutlined /></Button>
                     <Text> - Moves to the left</Text>
                     <div className='mb-2 d-block' />
                     <Button ghost type='primary' size='small'><RightOutlined /></Button>
                     <Text> - Moves to the right</Text>
                  </Col>
               </Row>
               <ProgressBar
                  progress={mergeStatus.progress}
                  error={mergeStatus.error}
                  info={mergeStatus.info}
               />
               {mergeStatus.progress === 100 ? (
                     <div className='text-center mb-4'>
                        <Button
                           danger
                           className='px-5'
                           href={baseURL + '/api/download/download-merged-video?fileName=' + fileName}
                           download={'true'}
                           target='_blank'
                        >
                           Click here to download
                        </Button>
                     </div>
                     ): null
                  }
               <Button 
                  type='primary' 
                  size='large' 
                  className='px-5' 
                  onClick={handleMerge}
                  loading={mergeStatus.disabled}
               >
                  Merge Videos
               </Button>
            </div>
         </div>
         <PricingTable
            visible={pricingVisible}
            pricingPackages={[
               {
                  title: 'Free',
                  features: [
                     'Merge up to 5 videos',
                     'All vidoes without watermark', 
                  ],
                  price: null
               },
               {
                  title: 'Premium',
                  features: [
                     'Merge up to 35 videos', 
                     'All Vidoes Without Watermark', 
                     'Custom Background Cover Image', 
                  ],
                  price: 5,
                  priceType: 'month',
                  checkout: {
                     servicetitle: 'TikTok Videos Merger',
                     serviceCode: 'tiktok_videos_merger',
                     serviceTier: 'Premium Version',
                     servicePrice: 5
                  }
               }
            ]}
         />
      </>
   );
}