import { BrowserRouter, Routes, Route } from "react-router-dom";
import { StateProvider } from './store';

// Styles And Fonts
import "@fontsource/nunito";
import 'antd/dist/antd.less';
import './assets/css/style.css';

// layouts
import MainLayout from './layouts/main.js';
import SecondaryLayout from './layouts/secondary.js';

// Pages
import Home from './pages/Home.js';
import SpecialOffer from "./pages/SpecialOffer";
import ContactMe from "./pages/ContactMe";
import AboutMe from "./pages/AboutMe";

// Auth
import UserDashboard from "./pages/auth/UserDashboard";
import Payment from "./pages/auth/Payment";
import Login from "./pages/auth/Login";

// Tools
import GetTikTokFollowers from './pages/tools/GetTikTokFollowers/index.js';
import YoutubeTrimmer from './pages/tools/YoutubeTrimmer.js';
import TikTokVideosMerger from './pages/tools/TikTokVideosMerger.js';
import TikTokDownloader from './pages/tools/TikTokDownloader.js';
import TikTokRandomCommentPicker from "./pages/tools/TikTokRandomCommentPicker";
import TikTokSongFinder from "./pages/tools/TikTokSongFinder";

function App() {

   return (
      <StateProvider>
         <BrowserRouter>
            <Routes>
               <Route path="/" element={<MainLayout />}>
                  <Route index element={<Home />} />
                  <Route path="get-tiktok-followers">
                     <Route index element={ <GetTikTokFollowers /> } />
                     <Route path="special-offer" element={<SpecialOffer />} />
                  </Route>
                  <Route path="youtube-trimmer" element={<YoutubeTrimmer />} />
                  <Route path="tiktok-vidoes-merger" element={<TikTokVideosMerger />} />
                  <Route path="tiktok-downloader" element={<TikTokDownloader />} />
                  <Route path="tiktok-random-comment-picker" element={<TikTokRandomCommentPicker />} />
                  <Route path="tiktok-song-finder" element={<TikTokSongFinder />} />
                  <Route path="payment" element={<Payment />} />
                  <Route path="dashboard" element={<UserDashboard />} />
                  <Route path="contact" element={<ContactMe />} />
                  <Route path="about" element={<AboutMe />} />
                  <Route path="login" element={<Login />} />
               </Route>
               {/* <Route element={<SecondaryLayout />}>
               </Route> */}
            </Routes>
         </BrowserRouter>
      </StateProvider>
   );
}

export default App;
