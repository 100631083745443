import { useState } from 'react';
import { Typography, Form, Input, Button, Alert } from 'antd';
import { axios, baseURL } from 'config';
import { scrollTo } from 'util';

const { Title, Text } = Typography;

export default function TikTokDownloader(props) {
   const [video, setVideo] = useState({ id: null, title: null, url: null });
   const [formStatus, setFormStatus] = useState({ disabled: false, error: '' });

   async function handleFormSubmit(data) {
      setFormStatus({ disabled: true, error: '' })

      try  {
         let response = await axios.post('/tool/tiktok-download-video', data)

         setVideo(response.data)
         setFormStatus({ false: true, error: '' })

         scrollTo('#downloadVideo')
      } catch(error) {
         console.log(error);
         setFormStatus({ disabled: false, error: error.response.data.message })
      }
   }

   return (
      <>
         <Title level={3} className='text-center mt-5'>
            TikTok Videos Downloader
         </Title>
         <Text className='d-block text-center mb-4'>
            Download TikTok videos without watermark easliy.
         </Text>
         <Form
            name='basic'
            onFinish={handleFormSubmit}
            autoComplete='off'
         >
            <Form.Item
               name='url'
               className='w-100 justify-content-center mb-4'
               rules={[{ required: true, message: 'Please enter a URL!' }]}
               wrapperCol={{ xs: 12, sm: 8, lg: 5 }}
            >
               <Input size='large' placeholder='Enter TikTok video URL' />
            </Form.Item>
            {Boolean(formStatus.error) &&
               <Form.Item
                  className='w-100 justify-content-center'
                  wrapperCol={{ xs: 12, sm: 5 }}
               >
                  <Alert 
                     type='error'
                     showIcon={true}
                     message={formStatus.error}
                  />
               </Form.Item>
            }
            <Form.Item
               className='w-100 justify-content-center'
               wrapperCol={{ xs: 8, sm: 4, lg: 3 }}
            >
               <Button type='primary' htmlType='submit' block loading={formStatus.disabled}>
                  Generate Download Link
               </Button>
            </Form.Item>
         </Form>

         <iframe 
            className={video.id ? null : 'd-none'}
            src={video.id ? 'https://www.tiktok.com/embed/v2/'+ video.id +'?lang=en-US&amp;referrer=http%3A%2F%2Flocalhost%3A3000%2Ftiktok-random-comment-picker' : null} 
            style={{
               marginBottom: '30px',
               width: '100%', 
               height: '728px', 
               display: 'block', 
               border: 'none', 
               visibility: 'unset'
            }}
         />

         <div id='downloadVideo' className={video.id ? 'text-center mt-3' : 'd-none'}>
               <Button 
                  type='primary' 
                  className='px-5'
                  href={baseURL + '/api/download/download-video-without-watermark?url=' + video.url + '&title=' + video.title}
                  download={'true'}
                  target='_blank'
               >
                  Download
               </Button>
         </div>
      </>
   );
}