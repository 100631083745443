import { Row, Col, Avatar, Typography, Button } from 'antd';

const { Title, Text } = Typography;

export default function AboutMe(props) {
   return (
      <>
         <Title level={3} className='text-center mt-5 mb-5'>
            About
         </Title>
         <Row justify='center' className='container-900' gutter={[20, 20]}>
            <Col>
               <Avatar 
                  className='d-block mx-auto mb-2' 
                  shape='round' 
                  size={200} 
                  src={'/images/me.png'}
               />
            </Col>
            <Col sm={8}>
               <Text className='d-block mb-4'>
                  Hi there! This is Gofur Nazarov, the creator of TikTuls.com. I have created this TikTuls.com website to solve some TikTok related problems and I hope you will benifit and enjoy the tools. If you like the tools that I created, please, consider supporting me by buying one of the premium services. It helps me to maintain the website, add more features to the existing tools and create more free tools for users to enjoy.

                  <br /><br />
                  I am a web and mobile developer. I started playing with web development in 2016 and started playing with mobile development during the last year. You can directly contact me through Whatsapp or Telegram if you have a custom offer.

                  <Text className='d-block mt-3' strong>
                     Whatsapp:
                     <Button type='link' href='https://wa.me/998997549723'>https://wa.me/gofurnazarov</Button>
                  </Text>
                  <Text className='d-block' strong>
                     Telegram:
                     <Button type='link' href='https://t.me/gofurnazarov'>https://t.me/gofurnazarov</Button>
                  </Text>
               </Text>
            </Col>
         </Row>
      </>
   );
}