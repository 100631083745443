import { Typography, Button } from 'antd';

const { Title, Text } = Typography;

export default function ContactMe(props) {
   return (
      <>
         <Title level={3} className='text-center mt-5'>
            Happy to hear from you!
         </Title>
         <Text className='d-block text-center mb-4 container-700'>
            Hi there! This is Gofur, creator of TikTuls.com. I love getting messages from my website visitors and I will read each of them. Feel free to contact me if you have any offers, issues with the website or even just to say a "Hi".
         </Text>

         <Text className='d-block text-center mb-4 container-700' strong>
            You can directly contact me through Whatsapp or Telegram.
         </Text>
         <Text className='d-block container-500' strong>
            Whatsapp:
            <Button type='link' href='https://bit.ly/3wxViPh'>https://wa.me/gofurnazarov</Button>
         </Text>
         <Text className='d-block container-500' strong>
            Telegram:
            <Button type='link' href='https://t.me/gofurnazarov'>https://t.me/gofurnazarov</Button>
         </Text>
      </>
   );
}