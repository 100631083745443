import { useContext } from 'react';
import { useLocation, useNavigate  } from 'react-router-dom'; 
import { axios } from 'config';
import { store } from 'store';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { Typography, Descriptions, Button, notification } from 'antd';


const { Title, Text } = Typography;

export default function Payment(props) {
   const { state, dispatch } = useContext(store)
   const navigate = useNavigate();
   const location = useLocation();

   if(location.state == null) {
      location.state = {}
   }

   async function buyCoins(tiktokId, coins) {
      try  {
         let params = {
            tiktokId: tiktokId,
            coins: coins
         }

         let response = await axios.post('/tool/tiktok-follower/buy-coins', params)

         navigate('/get-tiktok-followers', { 
            state: { boughtCoins: true } 
         })
      } catch(error) {
         console.log(error);

         notification.error({
            duration: null,
            placement: 'top',
            message: 'Attention!',
            description: 'Something went wrong! Try again or contact us using "Contact" page.',
         });
      }
   }

   async function buyService(name, email, serviceCode) {
      try  {
         let params = {
            name: name,
            email: email,
            serviceCode: serviceCode
         }

         let response = await axios.post('/user/register', params)

         dispatch({ type: 'LOGIN', payload: { email: email, user: response.data.user } })

         if(response.data.isNewRecord) {
            navigate('/dashboard', { 
               state: { 
                  login: response.data.user.email, 
                  password: response.data.user.password
               } 
            })
         } else {
            navigate('/dashboard')
         }
      } catch(error) {
         console.log(error);

         notification.error({
            duration: null,
            placement: 'top',
            message: 'Attention!',
            description: 'Something went wrong! Try again or contact us using "Contact" page.',
         });
      }
   }

   return (
      <>
         <Title level={3} className='text-center mb-5 mt-5'>
            Payment
         </Title>
         
         <div className='container-700 mb-5'>
            <Descriptions bordered layout='vertical'>
               <Descriptions.Item label='Service title'>{location.state.servicetitle}</Descriptions.Item>
               <Descriptions.Item label='Item'>{location.state.serviceTier}</Descriptions.Item>
               <Descriptions.Item label='Price'>${location.state.servicePrice}</Descriptions.Item>
            </Descriptions>
         </div>
         
         <div className='container-500'>
            <Text className='d-block mt-1 mb-5' type='danger'>
               After successful payment your "Login" and "Password" of your TikTuls account will be sent to your PayPal email (same when you pay with Debit or Credit Card).
            </Text>
         </div>

         <Text className='d-block text-center mb-2' type='warning' strong>
            Pay using the following methods:
         </Text>
         
         <div className='container-500'>
            <PayPalScriptProvider
               options={{ 
                  'client-id': 'AUqczlA2xiWWnQ2IlM25ZJE8ZqJjXk0TcUmmoCcLmG-UxE6Hror-ueepRjMHzR1nrjdxG3bz4Jddo6YR',
                  'currency': 'USD',
                  'enable-funding': 'venmo' 
               }}
            >
               <PayPalButtons 
                  style={{ 
                     label: 'pay',
                     color: 'blue',
                     layout: 'vertical'
                  }} 
                  createOrder={(data, actions) => {
                     return actions.order.create({
                        purchase_units: [{
                           description: 'TikTuls.com - ' + location.state.servicetitle,
                           amount: {
                              value: location.state.servicePrice,
                           },
                        }],
                     });
                  }}
                  onApprove={(data, actions) => {
                     return actions.order.capture().then((details) => {
                        const name = details.payer.name.given_name;
                        const email = details.payer.email_address;
                        const serviceCode = location.state.serviceCode;

                        const tiktokId = location.state.serviceParams.id;
                        const coins = location.state.serviceParams.coins;

                        if(serviceCode == 'get_tiktok_followers') {
                           buyCoins(tiktokId, coins)
                        } else {
                           buyService(name, email, serviceCode)
                        }
                     });
                  }}
                  onError={(error) => {
                     console.log(error)
                  }}
               />
            </PayPalScriptProvider>
         </div>
      </>
   );
}