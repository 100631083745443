import { useState, useEffect, useContext, useRef } from 'react';
import { axios } from 'config';
import { Row, Col, Typography, Form, Input, Button, Avatar } from 'antd';
import { store } from 'store';
import { scrollTo } from 'util';

import ProgressBar from 'components/ProgressBar.js';

const { Title, Text } = Typography;

// https://vm.tiktok.com/ZSdAHe9uG/

export default function TikTokSongFinder(props) {
   const player = useRef(null)
   const { state } = useContext(store)

   const formDefault = { progress: 0, error: '', disabled: false };
   const [form, setForm] = useState(formDefault);

   const [song, setSong] = useState({ songURL: '', downloadURL: '' }); 
   
   async function handleSearch(formData) {
      setForm((state) => {
         state = {...formDefault};
         state.disabled = true;

         return {...state};
      })

      try  {
         formData.socketId = state.socket.id;

         await axios.post('/tool/tiktok-find-song', formData)
      } catch(error) {
         console.log(error)

         setForm((state) => {
            state.error = error.response.data.message;
            state.disabled = false;

            return {...state};
         })
      }
   }

   useEffect(() => {
      if(state.socket) {
         state.socket.on('searching_song_progress', (value) => {
            setForm((state) => {
               state.progress = value;
               
               if(value == 100) {
                  state.disabled = false;
               }

               return {...state};
            })
         })
         
         state.socket.on('searching_song_error', (value) => {
            setForm((state) => {
               state.error = value;
               state.disabled = false;

               return {...state};
            })
         })

         state.socket.on('searching_song_success', (value) => {
            setSong(value)

            scrollTo('#downloadSong')
         })
      }
   }, [state.socket])

   return (
      <>
         <Title level={3} className='text-center  mt-5'>
            Find Original Song From a TikTok Sound 
         </Title>
         <Text className='d-block text-center mb-4'>
            Easily find original song from a TikTok sound and download it.
         </Text>

         <Form
            name='basic'
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={handleSearch}
         >
            <Row justify='center' align='middle'>
               <Col xs={12} sm={4}>
                  <Form.Item
                     name='url'
                     rules={[ { required: true, message: 'Please enter a URL!' } ]}
                  >
                     <Input size='large' placeholder='Enter TikTok video URL' />
                  </Form.Item>
               </Col>
               <Col span={12}>
                  <ProgressBar
                     progress={form.progress}
                     error={form.error}
                  />
               </Col>
               <Col xs={8} sm={2}>
                  <Form.Item>
                     <Button 
                        type='primary' 
                        htmlType='submit' 
                        block 
                        loading={form.disabled}
                     >
                        Search
                     </Button>
                  </Form.Item>
               </Col>
            </Row>
         </Form>
         
         <div style={ song.songURL ? null : { display: 'none' }  }>
            <iframe 
               onClick={(e) => {
                  e.preventDefault()
                  console.log(e)
               }}
               className='mb-4 mx-auto d-block'
               id='songMatches'
               src={song.songURL} 
               frameBorder='0'
               scrolling='no'
               sandbox='allow-top-navigation allow-scripts allow-forms allow-top-navigation-by-user-activation'
               style={{
                  minHeight: '405px',
                  width: '322px'
               }}
            />
            <div id='downloadSong'>
               <div className='text-center'>
                  <Button 
                     type='primary' 
                     href={song.songURL}
                     download={'true'}
                     target='_blank'
                  >
                     Download Song
                  </Button>
               </div>
            </div>
         </div>
      </>
   );
}