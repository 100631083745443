import { useState, useContext, useEffect } from 'react';
import { store } from 'store';
import { axios } from 'config';
import { useNavigate, useLocation  } from 'react-router-dom'; 
import { Typography, Form, Input, Button, Alert } from 'antd';

const { Title, Text } = Typography;

export default function GetTikTokFollowers(props) {
   const { state, dispatch } = useContext(store)
   const navigate = useNavigate();
   const location = useLocation();
   const [formStatus, setFormStatus] = useState({ disabled: false, error: '' });

   async function handleFormSubmit(data) {
      setFormStatus({ disabled: true, error: '' })

      try  {
         let params = {
            username: data.username
         }

         let response = await axios.post('/tool/tiktok-follower/login', params)

         if(response.data.id) 
         {
            localStorage.setItem('tiktok_id', response.data.id)
            dispatch({ type: 'SET_TIKTOK_ID', payload: { id:  response.data.id } })
            setFormStatus({ disabled: false, error: '' })
            navigate('/get-tiktok-followers')
         } 
         else 
         {
            setFormStatus({ disabled: false, error: 'Can not find a user by given username.' })
         }

      } catch(error) {
         console.log(error);
         setFormStatus({ disabled: false, error: 'Can not find a user by given username.' })
      }
   }

   useEffect(() => {
      if(location.state && location.state.redirect == true) {
         let tiktokId = localStorage.getItem('tiktok_id')
         
         if(tiktokId) {
            dispatch({ type: 'SET_TIKTOK_ID', payload: { id:  tiktokId } })
            navigate('/get-tiktok-followers')
         }
      }
   }, [state])

   return (
      <>
         <Title level={3} className='text-center mt-5'>
            Get TikTok Followers
         </Title>
         <Text className='d-block text-center mb-4'>
            Get thousands of followers easliy and become famous overnight.
         </Text>
         <Form
            name='basic'
            onFinish={handleFormSubmit}
            autoComplete='off'
         >
            <Form.Item
               name='username'
               className='w-100 justify-content-center mb-4'
               rules={[{ required: true, message: 'Please enter your username!' }]}
               wrapperCol={{ xs: 12, sm: 8, lg: 5 }}
            >
               <Input addonBefore='@' size='large' placeholder='Enter your TikTok username' />
            </Form.Item>
            {Boolean(formStatus.error) &&
               <Form.Item
                  className='w-100 justify-content-center'
                  wrapperCol={{ xs: 12, sm: 5 }}
               >
                  <Alert 
                     type='error'
                     showIcon={true}
                     message={formStatus.error}
                  />
               </Form.Item>
            }
            <Form.Item
               className='w-100 justify-content-center'
               wrapperCol={{ xs: 8, sm: 4, lg: 3 }}
            >
               <Button type='primary' htmlType='submit' block loading={formStatus.disabled}>
                  Continue
               </Button>
            </Form.Item>
         </Form>
      </>
   );
}
