import { Progress, Alert} from 'antd';

export default function ProgressBar(props) {
   return (
      <div className='text-center mb-3'>
         { Boolean(props.progress) &&
            <Progress 
               type='circle' 
               percent={props.progress} 
               status={Boolean(props.error) ? 'exception' : null} 
               format={props.format}
               width={80} 
            />
         }
         {(() => {
            if(props.progress >= 100) {
               return (<Alert 
                  message={'Done'} 
                  type='success' 
                  showIcon 
                  className='container-500 mt-3'
               />)
            } else if(props.error) {
               return (
                  <Alert 
                     message={props.error} 
                     type='error' 
                     showIcon 
                     className='container-500 mt-3'
                  />
               )
            } else if(props.progress && props.info) {
               return (
                  <Alert 
                     message={props.info} 
                     type='info' 
                     showIcon 
                     className='container-500 mt-3'
                  />
               )
            }
         })()}
      </div>
   )
}