import { useContext } from 'react';
import { useNavigate  } from 'react-router-dom'; 
import { axios, baseURL } from 'config';
import { store } from 'store';
import { Typography, Form, Button, Input, Alert, notification } from 'antd';
import { Coin } from '../icons';

const { Title, Text } = Typography;

export default function FollowersDashboard(props) {
   const { state } = useContext(store)
   const navigate = useNavigate();

   async function buyCoins() {
      try  {
         let params = {
            tiktokId: state.tiktokId,
            coins: 250
         }

         let response = await axios.post('/tool/tiktok-follower/buy-coins', params)

         navigate("/get-tiktok-followers/dashboard", { 
            state: { boughtCoins: true } 
         })
      } catch(error) {
         console.log(error);

         notification.error({
            duration: null,
            placement: 'top',
            message: 'Attention!',
            description: 'Something went wrong! Try again or contact us using "Contact" page.',
         });
      }
   }

   return (
      <>
         <Title level={3} className='text-center mb-5 mt-5'>
            Get free 500 coins and 80% discount
         </Title>

         <Title level={4} type='warning' className='text-center mb-3 mt-5'>
            1. Buy 5000 coins for only $5 &mdash; it usually costs $25 (80% discount)
         </Title>
         <Text className='d-block text-center container-900 mb-5'>
            Get 5000 coins for only $5. It usually costs $25 and we are giving 80% discount. This offer is only available for the first 100 users of the website. Hurry up or you will lose the opportunity because every second another user might be coming.
         </Text>
         <Alert
            message={<Title level={5} className='mb-0 text-left' ><Coin /> 5000 coins &mdash; only $5</Title>}
            type='info'
            className='mb-3 container-500 mb-5'
            action={
               <Button 
                  type='primary'
                  onClick={() => navigate("/payment", { 
                     state: { 
                        servicetitle: 'TikTok Followers',
                        serviceCode: 'get_tiktok_followers',
                        serviceTier: '5000 coins',
                        serviceParams: {
                           id: state.tiktokId,
                           coins: 5000
                        },
                        servicePrice: 5
                     } 
                  })}
               >
                  Buy now
               </Button>
            }
         />

         <Title level={4} type='warning' className='text-center mb-3 pt-5'>
            2. Get free 250 coins for promoting our video
         </Title>
         <Text className='d-block text-center container-900 mb-3'>
            Download the following video and post it on your TikTok account mentioning our website @tiktuls.com in the video description. And keep the video at least a month (otherwise your order will be cancelled). After posting the video on your TikTok account enter the post URL to the field under "Download" button and click the "Confirm" button so that we can check if you really shared our video and mentioned us in the description.
         </Text>
         <div className='container-900 text-center'>
            <video controls src={baseURL + "/public/share.mp4"} className='d-block mx-auto mb-3' width={200}></video>
            <Button 
               type='primary' 
               className='px-5 mb-5'
               href={baseURL + "/public/share.mp4"}
               download={true}
               target='_blank'
            >
               Download
            </Button>
         </div>
         <Form
            className='pb-5'
            name='basic'
            onFinish={buyCoins}
            autoComplete='off'
         >
            <Form.Item
               name='url'
               className='w-100 justify-content-center mb-4'
               rules={[{ required: true, message: 'Please enter your post URL!' }]}
               wrapperCol={{ xs: 12, sm: 8, lg: 5 }}
            >
               <Input size='large' placeholder='Enter your post URL' />
            </Form.Item>
            <Form.Item
               className='w-100 justify-content-center'
               wrapperCol={{ xs: 8, sm: 4, lg: 2 }}
            >
               <Button type='primary' htmlType='submit' block>
                  Confirm
               </Button>
            </Form.Item>
         </Form>

         <Title level={4} type='warning' className='text-center mb-3 mt-5'>
            3. Get free 250 coins for liking our videos on TikTok
         </Title>
         <div className='text-center mb-3 container-900'>
            <Text>Give a "Like" to only 3 videos of ours on TikTok and get 250 free coins immediately. Click <Button type='link' href='https://www.tiktok.com/@tiktuls.com' target='_blank' className='px-0'>@tiktuls.com</Button> to go our TikTok profile. After giving a "Like" to 3 videos of ours click the "Confirm" button below so that we can identify you really liked our 3 videos.</Text>
         </div>
         <div className='text-center'>
            <Button type='primary' className='px-5' onClick={() => buyCoins()}>
               Confirm
            </Button>
         </div>
      </>
   );
}