import { useState, useRef, useEffect, useContext } from 'react';
import { scrollTo } from 'util';
import ReactPlayer from 'react-player/lazy';
import { axios, baseURL } from 'config/index.js';
import ProgressBar from 'components/ProgressBar.js';
import { store } from 'store';
import { PricingTable } from 'components/PricingTable';
import { 
   Row,  Col,  Typography,  Form,  Input,  Select,  Button,  Slider, notification
} from 'antd';
import {  
   CaretLeftOutlined, CaretRightOutlined, PauseCircleFilled, PlayCircleFilled, ScissorOutlined 
} from '@ant-design/icons';


const { Title, Text } = Typography;
const { Option } = Select;

export default function YoutueTrimmer(props) {
   const { state } = useContext(store)
   const player = useRef(null);
   const [isPremium, setIsPremium] = useState(false);
   const [videoURL, setVideoURL] = useState('');
   const [currentTime, setCurrentTime] = useState(0);
   const [selectedTimeRange, setSelectedTimeRange] = useState([0, 0]);
   const [videoLength, setVideoLength] = useState(0);
   const [playing, setPlaying] = useState(true);

   const [fileName, setFileName] = useState('');
   const trimInitialStatus = { 
      disabled: false, 
      error: '', 
      info: 'Trimming', 
      progress: 0, 
   }
   const [trimStatus, setTrimStatus] = useState(trimInitialStatus);
   const [pricingVisible, setPricingVisible] = useState(false);


   function handleFormSubmit(data) {
      setTrimStatus(trimInitialStatus)
      setFileName('');
      setVideoURL(data.url);
      setPlaying(true)
      
      scrollTo('#videoOptions', 'end');
   }

   function convertToHours(value) {
      let seconds = value % 60;
      let minutes = Math.round(value / 60);
      let hours = '00';

      if(minutes >= 60) {
         hours = '0' + Math.round(minutes / 60);
         minutes = minutes % 60;
      }

      if(minutes < 10) minutes = '0' + minutes;
      if(seconds < 10) seconds = '0' + seconds;

      return hours +':'+ minutes +':'+ seconds;
   }

   function handleLeftSliderChange(method) {
      setSelectedTimeRange((oldVal) => {
         let left = oldVal[0];
         let right = oldVal[1];
         
         if(method==='increament') {
            if( left < videoLength ) left++;
         } else if(method==='decreament') {
            if( left > 0 ) left--;
         }

         return [left, right]
      })
      
   }

   function handleRightSliderChange(method) {
      setSelectedTimeRange((oldVal) => {
         let left = oldVal[0];
         let right = oldVal[1];

         if(method==='increament') {
            if( right < videoLength ) right++;
         }  else if(method==='decreament') {
            if( right > 0 ) right--;
         }

         return [left, right]
      })
      
   }

   async function handleTrim(data) {
      if(isPremium == false) {
         if(videoLength > 24000 || data.quality != '360p') {
            notification.info({
               duration: null,
               placement: 'top',
               message: 'Attention!',
               description: 'You have to buy premium version of the tool to trim videos longer than 40 minutes and higher quality than 360p.',
               btn: (<Button 
                  type='primary'
                  onClick={() => {
                     scrollTo('#pricingTable', 'start');
                     setPricingVisible(true);
                     notification.destroy();
                  }}
               >
                  Buy Premium Version
               </Button>)
            });
   
            return;
         }
      }
      
      setTrimStatus((state) => {
         state = trimInitialStatus
         state.disabled = true;
         state.progress = 1;

         return {...state}
      })

      try  {
         let params = {
            ...data,
            startTime: selectedTimeRange[0],
            duration: selectedTimeRange[1] - selectedTimeRange[0],
            url: videoURL,
            socketId: state.socket.id
         }
         let response = await axios.post('/tool/youtube-trim-videos', params)

      } catch(error) {
         console.log(error);
         setTrimStatus((state) => {
            state.disabled = false;
            state.error = error.response.data.message

            return {...state}
         })
      }
   }
   
   useEffect(() => {
      if(state.user && state.user.youtube_trimmer_available) {
         setIsPremium(true)
      }

      if(state.socket) {
         state.socket.on('trimming_video', (value) => {
            setTrimStatus((state) => {
               state.progress = value;
   
               return {...state};
            })
         })

         state.socket.on('trimming_video_success', (fileName) => {
            setTrimStatus((state) => {
               state.disabled = false;
   
               return {...state};
            })
            setFileName(fileName);
         })

         state.socket.on('trimming_video_error', (message) => {
            setTrimStatus((state) => {
               state.error = message;
               state.disabled = false;
   
               return {...state};
            })
         })
      }

   }, [state])

   useEffect(() => {
      if(selectedTimeRange[1] < currentTime) {
         player.current.seekTo(selectedTimeRange[0], 'seconds')
      }
   }, [currentTime, selectedTimeRange])

   useEffect(() => {
      player.current.seekTo(selectedTimeRange[0], 'seconds')
   }, [selectedTimeRange])

   return (
      <>
         <Title 
            level={3} 
            className='text-center mt-5' 
            type={isPremium ? 'warning' : null}
         >
            Youtube Trimmer {isPremium ? ' Premium' : null}
         </Title>
         <Text className='d-block text-center mb-4'>
            Trim Youtube videos easliy without any software.
         </Text>
         <Form name='basic' onFinish={handleFormSubmit} autoComplete='off'>
            <Form.Item
               name='url'
               className='w-100 justify-content-center mb-4'
               rules={[
                  { required: true, message: 'Please enter a Youtube URL!' },
                  ({ getFieldValue }) => ({
                     validator(_, url) {
                        if(!url)  return Promise.resolve();
                        
                        let isURLValid = ReactPlayer.canPlay(url)
                        
                        if (isURLValid) {
                           return Promise.resolve();
                        }

                        return Promise.reject(new Error('Invalid URL!'));
                     },
                  }),
               ]}
               wrapperCol={{ xs: 12, sm: 8, lg: 5 }}
            >
               <Input size='large' placeholder='Enter Youtube video URL' />
            </Form.Item>
            <Form.Item
               className='w-100 justify-content-center'
               wrapperCol={{ xs: 8, sm: 4, lg: 3 }}
            >
               <Button type='primary' htmlType='submit' block>
                  Continue
               </Button>
            </Form.Item>
         </Form>
         
         <div style={ videoURL ? null : { display: 'none' }  }>
            <div className='player-wrapper mb-4'>
               <ReactPlayer
                  className='react-player'
                  ref={player}
                  playing={playing}
                  url={videoURL}
                  width='100%'
                  height='100%'
                  config={{
                     youtube: {
                        playerVars: { 
                           light: 1,
                           autoplay: 0,
                           showinfo: 1,
                           origin: baseURL,
                        }
                     }
                  }}
                  onReady={() => { 
                     let duration = player.current.getDuration(); 
                     setVideoLength(duration);
                     setSelectedTimeRange([0, duration]);
                  }}
                  onProgress={(val) => setCurrentTime(val.playedSeconds)}
               />

            </div>
            
            <Title level={5} className='text-center'>
               Current time: { convertToHours(Math.round(currentTime)) }
            </Title>

            <div className='pt-5' id='videoOptions'>
               <Slider 
                  tipFormatter={convertToHours}
                  range 
                  min={0} 
                  max={videoLength} 
                  defaultValue={[0, videoLength]}
                  onChange={(value) => setSelectedTimeRange(value)}
                  value={selectedTimeRange}
                  tooltipVisible={true}
               />
               <Row justify='space-between' className='mb-5'>
                  <Col>
                     <Button 
                        size='small' 
                        className='me-2'
                        onClick={() => handleLeftSliderChange('decreament')}
                     >
                        <CaretLeftOutlined />
                     </Button>
                     <Button 
                        size='small'
                        onClick={() => handleLeftSliderChange('increament')}
                     >
                        <CaretRightOutlined />
                     </Button>
                  </Col>
                  <Col>
                     <Button 
                        onClick={() => setPlaying(!playing)}
                     >
                        { playing ? (
                           <PauseCircleFilled style={{ fontSize: '23px' }} />
                        ) : (
                           <PlayCircleFilled style={{ fontSize: '23px' }} />
                        )}
                     </Button>
                  </Col>
                  <Col>
                     <Button 
                        size='small' 
                        className='me-2' 
                        onClick={() => handleRightSliderChange('decreament')}
                     >
                        <CaretLeftOutlined />
                     </Button>
                     <Button 
                        size='small'
                        onClick={() => handleRightSliderChange('increament')}
                     >
                        <CaretRightOutlined />
                     </Button>
                  </Col>
               </Row>
               <Form 
                  onFinish={handleTrim} 
                  initialValues={{ 
                     format: 'mp4',
                     quality: '360p' 
                  }}
               >
                  <Row gutter={10} justify='center' >
                     <Form.Item 
                        name='format' 
                        label='Format' 
                        className='ant-col-12 ant-col-sm-4 px-2'
                     >
                        <Select>
                           <Option value='mp4'>.mp4</Option>
                           <Option value='mp3'>.mp3</Option>
                        </Select>
                     </Form.Item>
                     <Form.Item 
                        name='quality' 
                        label='Video quality'
                        className='ant-col-12 ant-col-sm-4 px-2'
                     >
                        <Select>
                           <Option value='360p'>360p</Option>
                           <Option value='480p'>480p (premium only)</Option>
                           <Option value='720p'>720p (premium only)</Option>
                           <Option value='1080p'>1080p (premium only)</Option>
                        </Select>
                     </Form.Item>
                  </Row>
                  <ProgressBar
                     progress={trimStatus.progress}
                     error={trimStatus.error}
                     info={trimStatus.info}
                  />
                  {trimStatus.progress === 100 ? (
                     <div className='text-center mb-4'>
                        <Button
                           danger
                           className='px-5'
                           href={baseURL + '/api/download/download-trimmed-video?fileName=' + fileName}
                           download={'true'}
                           target='_blank'
                        >
                           Click here to download
                        </Button>
                     </div>
                     ): null
                  }
                  <Form.Item className='text-center'>
                     <Button 
                        type='primary' 
                        className='px-5' 
                        size='large' 
                        htmlType='submit'
                        loading={trimStatus.disabled}
                     >
                        <ScissorOutlined /> Trim Video
                     </Button>
                  </Form.Item>
               </Form>
            </div>
         </div>

         <PricingTable
            visible={pricingVisible}
            pricingPackages={[
               {
                  title: 'Free',
                  features: [
                     'Unlimited videos',
                     'Output video quality only 360p', 
                     'Video length support up to 40 minutes', 
                  ],
                  price: null
               },
               {
                  title: 'Premium',
                  features: [
                     'Unlimited videos', 
                     'Output video quality up to 1080p', 
                     'Video length support up to 3 hours', 
                  ],
                  price: 5,
                  priceType: 'month',
                  checkout: {
                     servicetitle: 'Youtube Trimmer',
                     serviceCode: 'youtube_trimmer',
                     serviceTier: 'Premium Version',
                     servicePrice: 5
                  }
               }
            ]}
         />
      </>
   );
}
