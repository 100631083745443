import { Link } from 'react-router-dom';
import { Row, Col, Typography, Card, Avatar } from 'antd';
import { 
   TikTokToYoutube,
   YoutueTrimmer,
   TikTokFollowers,
   TikTokDownloader,
   TikTokRandomCommentPicker,
   TikTokSongFinder,
} from '../icons';


const { Title, Text } = Typography;

export default function Home(props) {
   return (
      <>
         <Title level={3} className='text-center mb-5 mt-5'>
            The only TikTok tools app you’ll need. <br />
            Learn how:
         </Title>
         <Row gutter={[25, 25]} align='stretch'>
            <Col xs={12} sm={6} md={4}>
               <Link 
                  to='/get-tiktok-followers'
                  state={{ redirect: true }}
               >
                  <Card className='drop-shadow text-center h-100'>
                     <Avatar 
                        className='mb-3 bg-transparent' 
                        shape='square' size={100} 
                        style={{ fontSize: '100px'  }} 
                        icon={<TikTokFollowers />} 
                     />
                     <Title className='text-primary mb-4' level={5}>Get TikTok Followers</Title>
                     <Text type='secondary' className='d-block'>
                        Get your FREE TikTok Followers and become famous overnight, with just a few simple clicks! If you are a new shining TikToker this free online tool helps you break TikTok algorithm and get famous easily.
                     </Text>
                  </Card>
               </Link>
            </Col>
            <Col xs={12} sm={6} md={4}>
               <Link to='/youtube-trimmer'>
                  <Card className='drop-shadow text-center h-100'>
                     <Avatar 
                        className='mb-3 bg-transparent' 
                        shape='square' 
                        size={100} 
                        style={{ fontSize: '100px'  }} 
                        icon={<YoutueTrimmer />} 
                     />
                     <Title className='text-primary mb-0' level={5}>Youtube Trimmer</Title>
                     <Text className='d-block mb-2'>(Youtube to TikTok Converter)</Text>
                     <Text type='secondary' className='d-block'>
                        Trim Youtube videos online without downloading whole video and cutting it with a software. You can use it to convert your Youtube videos to TikTok videos without hassle.
                     </Text>
                  </Card>
               </Link>
            </Col>
            <Col xs={12} sm={6} md={4}>
               <Link to='/tiktok-vidoes-merger'>
                  <Card className='drop-shadow text-center h-100'>
                     <Avatar 
                        className='mb-3 bg-transparent' 
                        shape='square' 
                        size={100} 
                        style={{ fontSize: '100px'  }} 
                        icon={<TikTokToYoutube />} 
                     />
                     <Title className='text-primary mb-0' level={5}>TikTok Videos Merger</Title>
                     <Text className='d-block mb-2'>(TikTok to Youtube Converter)</Text>
                     <Text type='secondary' className='d-block'>
                        Give several TikTok videos URLs to this tool and let it download all without watermark and combine as a single video. You can create one longer videos from several TikTok videos easily.
                     </Text>
                  </Card>
               </Link>
            </Col>
            <Col xs={12} sm={6} md={4}>
               <Link to='/tiktok-random-comment-picker'>
                  <Card className='drop-shadow text-center h-100'>
                     <Avatar 
                        className='mb-3 bg-transparent' 
                        shape='square' 
                        size={100} 
                        style={{ fontSize: '100px'  }} 
                        icon={<TikTokRandomCommentPicker />} 
                     />
                     <Title className='text-primary mb-4' level={5}>
                        TikTok Random Comment Picker
                     </Title>
                     <Text type='secondary' className='d-block'>
                        Now you can do giveaways on TikTok with the help of this tool. Engage with your followers and share your valuable gifts with your followers. 
                     </Text>
                  </Card>
               </Link>
            </Col>
            <Col xs={12} sm={6} md={4}>
               <Link to='/tiktok-song-finder'>
                  <Card className='drop-shadow text-center h-100'>
                     <Avatar 
                        className='mb-3 bg-transparent' 
                        shape='square' 
                        size={100} 
                        style={{ fontSize: '100px'  }} 
                        icon={<TikTokSongFinder />} 
                     />
                     <Title className='text-primary mb-4' level={5}>
                        Song Identifier From TikTok Sound 
                     </Title>
                     <Text type='secondary' className='d-block'>
                        You can identify songs used in TikTok videos easily with the help of this tool.
                     </Text>
                  </Card>
               </Link>
            </Col>
            <Col xs={12} sm={6} md={4}>
               <Link to='/tiktok-downloader'>
                  <Card className='drop-shadow text-center h-100'>
                     <Avatar 
                        className='mb-3 bg-transparent' 
                        shape='square' size={100} 
                        style={{ fontSize: '100px'  }} 
                        icon={<TikTokDownloader />} 
                     />
                     <Title className='text-primary mb-4' level={5}>
                        TikTok Videos Downloader
                     </Title>
                     <Text type='secondary' className='d-block'>
                        Download TikTok videos without watermark easily.
                     </Text>
                  </Card>
               </Link>
            </Col>
         </Row>
      </>
   );
}
