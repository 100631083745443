import { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation  } from 'react-router-dom'; 
import { axios } from 'config';
import { store } from 'store';
import { Coin, UsersFilled } from 'icons';
import { LogoutOutlined } from '@ant-design/icons';
import { 
   Row, Col, Typography, Tabs, Statistic, Button, Avatar, Alert, notification, message 
} from 'antd';


const { Title, Text } = Typography;
const { TabPane } = Tabs;

export default function FollowersDashboard(props) {
   const { state, dispatch } = useContext(store)
   const [creator, setCreator] = useState({ avatar_300x300: { url_list: [] } });
   const [isRedirected, setIsRedirected] = useState(false);
   const [user, setUser] = useState({});
   const navigate = useNavigate();
   const location = useLocation();
   
   async function findCreator () {
      try  {
         let params = {
            params: { id: state.tiktokId }
         }

         let response = await axios.get('/tool/tiktok-follower/find-creator', params)

         if(response.data.creator == null) {
            if(user.id < 20) {
               notification.info({
                  duration: null,
                  placement: 'top',
                  message: 'Congratulations!',
                  description: 'You are one of our early 20 users. We are giving free 500 coins and 80% discount for buying extra coins to our early 20 users.',
                  btn: (<Button 
                        type='primary'
                        onClick={() => {
                           notification.destroy();
                           navigate('/get-tiktok-followers/special-offer')
                        }}
                     >
                        Get Free Coins
                     </Button>)
               });
            } else {
               notification.warning({
                  duration: null,
                  placement: 'top',
                  message: 'Attention!',
                  description: 'No users left to follow. Come back later or buy coins.'
               });
            }
         } else {
            setCreator(response.data.creator)
         }
      } catch(error) {
         console.log(error);
      }
   }
   
   async function skipCreator (checkIfFollowed) {
      try  {
         let params = {
            followerId: state.tiktokId,
            creatorId: creator.uid
         }
         
         if(checkIfFollowed) params.checkIfFollowed = true;

         let response = await axios.post('/tool/tiktok-follower/skip-creator', params)
         
         if(response.data.isFollowed) {
            setUser((state) => {
               state.coins = state.coins + 5;

               return {...state}
            })
            message.success(<Text>You earned 5 <Coin /> </Text>);
         }
         
         await findCreator();

         setIsRedirected(false)
      } catch(error) {
         console.log(error);
      }
   }

   async function exchangeCoins(coins) {
      try  {
         let params = {
            id: state.tiktokId,
            coins: coins
         }

         let response = await axios.post('/tool/tiktok-follower/exchange-coins', params)
         
         if(response.data.message == 'Done')
         {
            notification.info({
               duration: null,
               placement: 'top',
               message: 'Congratulations!',
               description: 'You have successfully bought followers. You order will be fulfilled as soon as possible (it might take a few days or a few hours depending how many followers you bought).',
            });
            getUserDetails();
         }
         else
         {
            notification.info({
               duration: null,
               placement: 'top',
               message: 'Attention!',
               description: 'You do not have enough coins.',
            });
         }
      } catch(error) {
         console.log(error);
      }
   }

   function logout() {
      localStorage.removeItem('tiktok_id');
      dispatch({ type: 'SET_TIKTOK_ID', payload: { id:  null } })
   }

   async function getUserDetails () {
      try  {
         let params = {
            params: {
               id: state.tiktokId,
            }
         }

         let response = await axios.get('/tool/tiktok-follower/get-follower-details', params)
         setUser(response.data.follower);
      } catch(error) {
         console.log(error);
      }
   }

   useEffect(() => {
      if(location.state && location.state.boughtCoins) {
         message.success(<Text>You got coins <Coin /> </Text>);
      }
   }, [])

   useEffect(() => {
      if(state.isReady && state.tiktokId == null) {
         navigate('/')
      }

      if(state.isReady && state.tiktokId) {
         getUserDetails();
         findCreator();
      }
   }, [state])

   return (
      <Tabs type='card' centered>
         <TabPane tab='Earn coins' key='1'>
            <Title level={5} className='text-center'>
               Your coins: 
               <Text type='warning'> {user.coins} <Coin /></Text>
            </Title>
            <Avatar 
               className='d-block mx-auto mb-3'
               shape='square' 
               size={270} 
               src={creator.avatar_300x300.url_list[0]} 
            />

            <Row gutter={10} className='container-500 mb-4'>
               <Col span={isRedirected ? 12 : 6}>
                  <Button 
                     onClick={() => {
                        if(isRedirected) {
                           skipCreator(true)
                        } else {
                           skipCreator()
                        }
                     }}
                     type='default' 
                     shape='round' 
                     size='large' 
                     className='me-3' 
                     block
                     disabled={Boolean(creator)}
                  >
                     Next
                  </Button>
               </Col>
               {Boolean(isRedirected == false) &&
                  <Col span={6}>
                     <Button 
                        onClick={() => setIsRedirected(true)}
                        href={'https://www.tiktok.com/@' + creator.unique_id}
                        target='_blank'
                        type='primary' 
                        shape='round' 
                        size='large' 
                        block
                        disabled={Boolean(creator)}
                     >
                        Follow  +5 <Coin />
                     </Button>
                  </Col>
               }
            </Row>
            <div className='text-center'>
               <Text type='secondary'>Please do not unfollow after following the user, you may not get stars and your <br /> orders would be cancelled as well. Thank you!</Text>
            </div>
         </TabPane>
         <TabPane tab='Get Followers' key='2'>
            <Avatar 
               className='d-block mx-auto mb-2' 
               shape='round' 
               size={100} 
               src={user.avatar}
            />
            <Title className='text-center' level={4}>@{user.username}</Title>
            <Row gutter={10} justify='center' className='text-center mb-4'>
               <Col xs={6} sm={2}>
                  <Statistic title='Coins' value={user.coins} prefix={<Coin />} />
               </Col>
               <Col xs={6} sm={2}>
                  <Statistic title='Followers' value={user.followers} prefix={<UsersFilled />} />
               </Col>
            </Row>

            <Tabs className='container-700 mb-5' centered>
               <TabPane tab='Buy Followers' key='1'>
                  <Alert
                     message={<Title level={5} className='mb-0' >
                        <UsersFilled /> 5
                     </Title>}
                     type='info'
                     className='mb-3'
                     action={<Button type='primary' onClick={() => exchangeCoins(50)}><Coin /> 50 coins</Button>}
                  />
                  <Alert
                     message={<Title level={5} className='mb-0' >
                        <UsersFilled /> 10
                     </Title>}
                     type='info'
                     className='mb-3'
                     action={<Button type='primary' onClick={() => exchangeCoins(100)}><Coin /> 100 coins</Button>}
                  />
                  <Alert
                     message={<Title level={5} className='mb-0' >
                        <UsersFilled /> 15
                     </Title>}
                     type='info'
                     className='mb-3'
                     action={<Button type='primary' onClick={() => exchangeCoins(150)}><Coin /> 150 coins</Button>}
                  />
                  <Alert
                     message={<Title level={5} className='mb-0' >
                        <UsersFilled /> 20
                     </Title>}
                     type='info'
                     className='mb-3'
                     action={<Button type='primary' onClick={() => exchangeCoins(200)}><Coin /> 200 coins</Button>}
                  />
                  <Alert
                     message={<Title level={5} className='mb-0' >
                        <UsersFilled /> 25
                     </Title>}
                     type='info'
                     className='mb-3'
                     action={<Button type='primary' onClick={() => exchangeCoins(250)}><Coin /> 250 coins</Button>}
                  />
                  <Alert
                     message={<Title level={5} className='mb-0' >
                        <UsersFilled /> 50
                     </Title>}
                     type='info'
                     className='mb-3'
                     action={<Button type='primary' onClick={() => exchangeCoins(500)}><Coin /> 500 coins</Button>}
                  />
                  <Alert
                     message={<Title level={5} className='mb-0' >
                        <UsersFilled /> 75
                     </Title>}
                     type='info'
                     className='mb-3'
                     action={<Button type='primary' onClick={() => exchangeCoins(750)}><Coin /> 750 coins</Button>}
                  />
                  <Alert
                     message={<Title level={5} className='mb-0' >
                        <UsersFilled /> 100
                     </Title>}
                     type='info'
                     className='mb-3'
                     action={<Button type='primary' onClick={() => exchangeCoins(1000)}><Coin /> 1000 coins</Button>}
                  />
                  <Alert
                     message={<Title level={5} className='mb-0' >
                        <UsersFilled /> 200
                     </Title>}
                     type='info'
                     className='mb-3'
                     action={<Button type='primary' onClick={() => exchangeCoins(2000)}><Coin /> 2000 coins</Button>}
                  />
                  <Alert
                     message={<Title level={5} className='mb-0' >
                        <UsersFilled /> 300
                     </Title>}
                     type='info'
                     className='mb-3'
                     action={<Button type='primary' onClick={() => exchangeCoins(3000)}><Coin /> 3000 coins</Button>}
                  />
                  <Alert
                     message={<Title level={5} className='mb-0' >
                        <UsersFilled /> 400
                     </Title>}
                     type='info'
                     className='mb-3'
                     action={<Button type='primary' onClick={() => exchangeCoins(4000)}><Coin /> 4000 coins</Button>}
                  />
                  <Alert
                     message={<Title level={5} className='mb-0' >
                        <UsersFilled /> 500
                     </Title>}
                     type='info'
                     className='mb-3'
                     action={<Button type='primary' onClick={() => exchangeCoins(50)}><Coin /> 5000 coins</Button>}
                  />
                  <Alert
                     message={<Title level={5} className='mb-0' >
                        <UsersFilled /> 1000
                     </Title>}
                     type='info'
                     className='mb-3'
                     action={<Button type='primary'><Coin /> 10000 coins</Button>}
                  />
               </TabPane>
               <TabPane tab='Buy Coins' key='2'>
                  <Alert
                     message={<Title level={5} className='mb-0' >
                        <Coin /> 250 coins &mdash; $2
                     </Title>}
                     type='info'
                     className='mb-3'
                     action={
                        <Button 
                           type='primary' 
                           onClick={() => navigate("/payment", { 
                              state: { 
                                 servicetitle: 'TikTok Followers',
                                 serviceCode: 'get_tiktok_followers',
                                 serviceTier: '250 coins',
                                 serviceParams: {
                                    id: state.tiktokId,
                                    coins: 250
                                 },
                                 servicePrice: 2
                              } 
                           })}
                        >
                           Buy now
                        </Button>
                     }
                  />
                  <Alert
                     message={<Title level={5} className='mb-0' >
                        <Coin /> 750 coins &mdash; $5
                     </Title>}
                     type='info'
                     className='mb-3'
                     action={
                        <Button 
                           type='primary' 
                           onClick={() => navigate("/payment", { 
                              state: { 
                                 servicetitle: 'TikTok Followers',
                                 serviceCode: 'get_tiktok_followers',
                                 serviceTier: '750 coins',
                                 serviceParams: {
                                    id: state.tiktokId,
                                    coins: 750
                                 },
                                 servicePrice: 5
                              } 
                           })}
                        >
                           Buy now
                        </Button>
                     }
                  />
                  <Alert
                     message={<Title level={5} className='mb-0' >
                        <Coin />1750 coins &mdash; $10
                     </Title>}
                     type='info'
                     className='mb-3'
                     action={
                        <Button 
                           type='primary' 
                           onClick={() => navigate("/payment", { 
                              state: { 
                                 servicetitle: 'TikTok Followers',
                                 serviceCode: 'get_tiktok_followers',
                                 serviceTier: '1750 coins',
                                 serviceParams: {
                                    id: state.tiktokId,
                                    coins: 1750
                                 },
                                 servicePrice: 10
                              } 
                           })}
                        >
                           Buy now
                        </Button>
                     }
                  />
                  <Alert
                     message={<Title level={5} className='mb-0' >
                        <Coin /> 3750 coins &mdash; $20
                     </Title>}
                     type='info'
                     className='mb-3'
                     action={
                        <Button 
                           type='primary' 
                           onClick={() => navigate("/payment", { 
                              state: { 
                                 servicetitle: 'TikTok Followers',
                                 serviceCode: 'get_tiktok_followers',
                                 serviceTier: '3750 coins',
                                 serviceParams: {
                                    id: state.tiktokId,
                                    coins: 3750
                                 },
                                 servicePrice: 20
                              } 
                           })}
                        >
                           Buy now
                        </Button>
                     }
                  />
                  <Alert
                     message={<Title level={5} className='mb-0' >
                        <Coin /> 5000 coins &mdash; $25
                     </Title>}
                     type='info'
                     className='mb-3'
                     action={
                        <Button 
                           type='primary' 
                           onClick={() => navigate("/payment", { 
                              state: { 
                                 servicetitle: 'TikTok Followers',
                                 serviceCode: 'get_tiktok_followers',
                                 serviceTier: '5000 coins',
                                 serviceParams: {
                                    id: state.tiktokId,
                                    coins: 5000
                                 },
                                 servicePrice: 25
                              } 
                           })}
                        >
                           Buy now
                        </Button>
                     }
                  />
               </TabPane>
            </Tabs>
            
            <Button 
               ghost 
               className='d-block px-5 mb-3 container-500' 
               type='danger' 
               block
               onClick={logout}
            >
               <LogoutOutlined /> Log out
            </Button>
         </TabPane>
      </Tabs>
   );
}