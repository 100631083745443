import Icon from '@ant-design/icons';
import { ReactComponent as SvgUsers } from '../assets/icons/users.svg';
import { ReactComponent as SvgTikTokToYoutube } from '../assets/icons/tiktok-to-youtube.svg';
import { ReactComponent as SvgYoutueTrimmer } from '../assets/icons/youtube-trimmer.svg';
import { ReactComponent as SvgTikTokFollowers } from '../assets/icons/tiktok-followers.svg';
import { ReactComponent as SvgTikTokDownloader } from '../assets/icons/tiktok-downloader.svg';
import { ReactComponent as SvgRandomCommentPicker } from '../assets/icons/random-comment-picker.svg';
import { ReactComponent as SvgSongFinder } from '../assets/icons/song-finder.svg';
import { ReactComponent as SvgCoin } from '../assets/icons/coin.svg';

export const UsersFilled = props => <Icon component={SvgUsers} {...props} />;
export const TikTokToYoutube = props => <Icon component={SvgTikTokToYoutube} {...props} />;
export const YoutueTrimmer = props => <Icon component={SvgYoutueTrimmer} {...props} />;
export const TikTokFollowers = props => <Icon component={SvgTikTokFollowers} {...props} />;
export const TikTokDownloader = props => <Icon component={SvgTikTokDownloader} {...props} />;
export const TikTokRandomCommentPicker = props => <Icon component={SvgRandomCommentPicker} {...props} />;
export const TikTokSongFinder = props => <Icon component={SvgSongFinder} {...props} />;
export const Coin = props => <Icon component={SvgCoin} {...props} />;
