import { PageHeader, Layout } from 'antd';
import { Outlet, useNavigate  } from "react-router-dom"; 
const { Header, Content, Footer  } = Layout;



function Secondary(props) {
   const navigate = useNavigate();

   return (
      <Layout>
         <Header>
            <PageHeader
               className="site-page-header"
               onBack={() => navigate(-1)}
               title="Get Followers"
            />
         </Header>
         <Content style={{ margin: '24px 16px 0' }}>
            <Outlet />
         </Content>
         <Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Footer>
      </Layout>
   );
}

export default Secondary;
