import { useNavigate  } from 'react-router-dom'; 
import { CheckOutlined } from '@ant-design/icons';
import { 
   Row,  Col,  Typography,  Button, Card, List
} from 'antd';

const { Title, Text } = Typography;

export function PricingTable(props) {
   const navigate = useNavigate();
   let columnSize = 12;

   if(props.pricingPackages.length == 2) {
      columnSize = 6;
   }

   if(props.pricingPackages.length == 3) {
      columnSize = 4;
   }

   if(props.pricingPackages.length == 4) {
      columnSize = 3;
   }

   if(props.visible == false) {
      return <></>;
   }

   return (
      <>
         <Title level={4} className='text-center mb-5 pt-5' id='pricingTable'>Pricing</Title>

         <Row gutter={[20, 20]} className='text-center'>
            {props.pricingPackages.map((pricingPackage) => {
               return (
                  <Col xs={12} sm={columnSize} md={columnSize} key={pricingPackage.title}>
                     <Card bordered={false}>
                        <List
                           header={<Title level={4}>{pricingPackage.title}</Title>}
                           dataSource={pricingPackage.features}
                           renderItem={item => (
                              <List.Item className='justify-content-center'>
                                 <Text type='success' className='me-2'> <CheckOutlined /> </Text>
                                 <Text > {item} </Text>
                              </List.Item>
                           )}
                           footer={pricingPackage.price && 
                              <div>
                                 <Title level={5} className='pb-3'>
                                    Price: <br />
                                    ${pricingPackage.price} / {pricingPackage.priceType}
                                 </Title>
                                 <Button 
                                    type='primary' 
                                    size='large' 
                                    onClick={() => navigate("/payment", { 
                                       state: pricingPackage.checkout
                                    })}
                                 >
                                    Buy now
                                 </Button>
                              </div>
                           }
                        />
                     </Card>
                  </Col>
               )
            })}
         </Row>
      </>
   )
}